import { Locales } from "./locales"

import en from "./en.json"
import es from "./es.json"
import ru from "./ru.json"
import de from "./de.json"
import it from "./it.json"
import fr from "./fr.json"
import pt from "./pt.json"
import zhCn from "./zh-cn.json"
import zhTw from "./zh-tw.json"
import pl from "./pl.json"
import ja from "./ja.json"

export const messages: any = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.RU]: ru,
  [Locales.DE]: de,
  [Locales.IT]: it,
  [Locales.FR]: fr,
  [Locales.PT]: pt,
  [Locales.ZH_CN]: zhCn,
  [Locales.ZH_TW]: zhTw,
  [Locales.PL]: pl,
  [Locales.JA]: ja
}

export const defaultLocale = Locales.EN
