export enum Locales {
  EN = "en",
  ES = "es",
  RU = "ru",
  DE = "de",
  IT = "it",
  FR = "fr",
  PT = "pt",
  ZH_CN = "zh-cn",
  ZH_TW = "zh-tw",
  PL = "pl",
  JA = "ja"
}
